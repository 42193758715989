<template>
  <div class="page container">
    <header class="header-w">
      <span>添加质疑</span>
    </header>
    <div class="form-box">
      <div class="form-container">
        <van-cell title="字段OID" :value="queryItem && queryItem.key" />
        <van-cell title="字段标签" :value="queryItem && queryItem.title" />
        <van-cell title="创建者" :value="user_name" />
        <van-cell title="当前值" :value="queryItem && queryItem.value" />
        <van-cell title="当前用户" :value="fullName" />
        <van-field
          v-model="message"
          rows="3"
          label="质疑文本"
          type="textarea"
          border
          placeholder="请输入"
        />
      </div>
      <div class="button-box">
        <van-button type="primary" @click="onSubmit" style="margin-right: 30px"
          >保 存</van-button
        >
        <van-button type="default" @click="onCancalClick">取 消</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup, Field, Button, Toast } from "vant";
import service from "../js/service";
export default {
  name: "creat-query",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Button.name]: Button,
  },
  data() {
    return {
      message: "",
      queryItem: null,
      user_name: "",
    };
  },
  computed: {
    fullName() {
      let roleName = this.$route.query.roleName;
      return `${this.user_name}(${roleName})`;
    },
  },
  mounted() {
    this.getUserInfo();
    let obj = JSON.parse(sessionStorage.getItem("queryItem"));
    obj.key = obj.key != "" ? obj.key.substring(1) : "";
    this.queryItem = obj;
  },
  methods: {
    async getUserInfo() {
      const res = await service.sendReq("get-user-info", null, "get");
      if (res.data.code === 200) {
        const data = res.data.data;
        this.user_name = data.user_name;
      }
    },
    onSubmit() {
      this.message = this.message.trim();
      if (this.message == "") {
        return Toast("请输入质疑文本");
      }
      this.handleCreateQuery();
    },
    async handleCreateQuery() {
      const {
        project_id,
        env_name,
        subject_id,
        folder_oid,
        form_oid,
        folder_form_id,
        subject_item_id,
        roleId,
        envId,
      } = this.$route.query;
      const res = await service.sendReq(
        "create-query",
        {
          project_id,
          env_name,
          subject_id,
          subject_item_id,
          role_id: roleId,
          add_reason: this.message,
          field_oid: this.queryItem ? this.queryItem.key : "",
          env_id: envId,
          field_value: this.queryItem ? this.queryItem.value : "",
        },
        "post"
      );
      if (res.data.code === 200) {
        let msg = res.data.msg;
        Toast(msg);
        setTimeout(() => {
          this.handleBack();
        }, 500);
      } else {
        let msg = res.data.msg;
        msg = JSON.stringify(msg);
        Toast(msg);
      }
    },
    onCancalClick() {
      this.handleBack();
    },
    handleBack() {
      this.$router.back(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
.container {
  background-color: $bg-grey;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.header-w {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-color;
  color: white;
  padding: 0 8px;
  height: 50px;
}
.form-box {
  width: 100%;
  height: calc(100vh - 76px);
  padding-top: 10px;
}
.form-container {
  width: 92%;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
}
.button-box {
  text-align: center;
  // background-color: #fff;
  margin-top: 10px;
}
</style>